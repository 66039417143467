import React, { useEffect } from 'react';
import './FinishScreen.css';
import { io } from 'socket.io-client';
import { Container } from '@mui/material';

const FinishScreen = ({ onRestart, points }) => {
  const socket = io('wss://tnf.co.il/ws');

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    socket.on('message', (data) => {
      console.log('Message from server:', data);
    });

    // Clear quest progress when the finish screen is shown
    localStorage.removeItem('questProgress');

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleRestart = () => {
    onRestart();
  };

  const handleWatchYoutube = () => {
    window.location.href = 'https://www.youtube.com';
  };

  const handleTapHamster = () => {
    alert('Ты идиот!');
  };

  return (
    <Container className={`finish-screen ${points >= 80 ? 'high-score' : points >= 50 ? 'medium-score' : 'low-score'}`}>
      <h1>Congratulations! You've completed the quest!</h1>
      <p>Your score: {points}</p>
      <div className="options">
        <button className="option-button" onClick={handleRestart}>Start another quest</button>
        <button className="option-button" onClick={handleWatchYoutube}>Watch YouTube</button>
        <button className="option-button" onClick={handleTapHamster}>Tap the hamster</button>
      </div>
    </Container>
  );
};

export default FinishScreen;
