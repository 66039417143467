import React from 'react';
import './ImageStage.css';

const ImageStage = ({ character, text, onFileChange, onSubmit, showHint, onHint, showGiveUp, onGiveUp, resultMessage }) => (
  <div className="image-stage-container">
    <h2>{character}</h2>
    <p>{text}</p>
    <form onSubmit={onSubmit}>
      <input type="file" onChange={onFileChange} />
      <button type="submit" className="styled-button">Upload</button>
    </form>
    {showHint && (
      <button className="styled-button" onClick={onHint}>Hint</button>
    )}
    {showGiveUp && (
      <button className="styled-button" onClick={onGiveUp}>Give up</button>
    )}
    <p className="result-message">{resultMessage}</p>
  </div>
);

export default ImageStage;

