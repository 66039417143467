import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const executePayment = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const paymentId = urlParams.get('paymentId');
      const PayerID = urlParams.get('PayerID');

      if (!paymentId || !PayerID) {
        console.error('Missing paymentId or PayerID in URL');
        return;
      }

      try {
        const response = await axios.post('https://tnf.co.il/api/payment/execute', {
          paymentId,
          PayerID
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Payment executed successfully:', response.data);
        navigate('/quest');
      } catch (error) {
        console.error('Payment execution error:', error);
      }
    };

    executePayment();
  }, [navigate, token]);

  return (
    <div>
      <h1>Payment Successful</h1>
      <p>Redirecting to the quest...</p>
    </div>
  );
};

export default PaymentSuccess;

