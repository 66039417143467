import React, { useEffect, useState } from 'react';
import './StartScreen.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { io } from 'socket.io-client';
import { Container } from '@mui/material';

const socket = io('wss://tnf.co.il/ws');

const StartScreen = ({ token }) => {
  const [quests, setQuests] = useState([]);

  useEffect(() => {
    const fetchQuests = async () => {
      try {
        const response = await axios.get('https://tnf.co.il/api/quests/');
        console.log('Загруженные квесты:', response.data);
        setQuests(response.data);
      } catch (error) {
        console.error('Ошибка загрузки квестов:', error);
      }
    };

    fetchQuests();
  }, []);

  useEffect(() => {
    console.log('Состояние квестов обновлено:', quests);
  }, [quests]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    socket.on('message', (data) => {
      console.log('Message from server:', data);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Container className="start-screen">
      <h1>Choose a Quest</h1>
      <ul>
        {quests.length > 0 ? (
          quests.map((quest) => (
            <li key={quest._id}>
              <Link to={`/quest/${quest._id}`} className="quest-button">{quest.name}</Link>
            </li>
          ))
        ) : (
          <p>No quests available</p>
        )}
      </ul>
      <div className="navigation-buttons">
        {!token ? (
          <>
            <Link to="/register">
              <button className="navigation-button">Register</button>
            </Link>
            <Link to="/login">
              <button className="navigation-button">Login</button>
            </Link>
          </>
        ) : (
          <Link to="/account">
            <button className="navigation-button">My Account</button>
          </Link>
        )}
      </div>
    </Container>
  );
};

export default StartScreen;

