import React from 'react';
import './SpeechStage.css';

const SpeechStage = ({ character, text, onNext }) => (
  <div className="speech-stage-container">
    <h2>{character}</h2>
    <p>{text}</p>
    <button className="styled-button" onClick={onNext}>Next</button>
  </div>
);

export default SpeechStage;

