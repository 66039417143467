import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './QuestPage.css';

const QuestPage = ({ token, onStart }) => {
  const { id } = useParams();
  const [quest, setQuest] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const loadProgress = () => {
    const savedProgress = localStorage.getItem('questProgress');
    if (savedProgress) {
      const progress = JSON.parse(savedProgress);
      if (Date.now() - progress.timestamp < 3 * 60 * 60 * 1000) {
        return progress;
      } else {
        localStorage.removeItem('questProgress');
      }
    }
    return null;
  };

  useEffect(() => {
    const fetchQuest = async () => {
      try {
        const response = await axios.get(`https://tnf.co.il/api/quests/${id}`, {
          headers: token ? { Authorization: `Bearer ${token}` } : {}
        });
        setQuest(response.data);

        const savedProgress = loadProgress();
        if (savedProgress && savedProgress.questId === id) {
          console.log('Saved progress found:', savedProgress);
        }
      } catch (error) {
        console.error('Error fetching quest:', error);
        setErrorMessage('Error fetching quest.');
      }
    };

    const checkPayment = async () => {
      try {
        const response = await axios.get(`https://tnf.co.il/api/account/quest-status/${id}`, {
          headers: token ? { Authorization: `Bearer ${token}` } : {}
        });
        setIsPaid(response.data.isPaid);
      } catch (error) {
        console.error('Error checking payment status:', error);
        setErrorMessage('Error checking payment status.');
      }
    };

    fetchQuest();
    if (token) {
      checkPayment();
    }
  }, [id, token]);

  const handlePayment = async () => {
    try {
      const response = await axios.post('https://tnf.co.il/api/account/pay', {
        quest_id: id,
        amount: quest.price,
        payment_method: 'paypal'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const { redirect_url } = response.data;
      window.location.href = redirect_url;
    } catch (error) {
      console.error('Error initiating payment:', error);
      setErrorMessage('Error initiating payment.');
    }
  };

  const handleStartQuest = () => {
    const savedProgress = loadProgress();
    if (savedProgress && savedProgress.questId === id) {
      onStart(id, 'Gamer', savedProgress.currentStep, savedProgress.currentSubStep);
    } else {
      onStart(id, 'Gamer');
    }
    navigate('/quest');
  };

  if (!quest) {
    return <div>Loading quest...</div>;
  }

  return (
    <div className="quest-page-container">
      <h1 className="quest-title">{quest.name}</h1>
      <p className="quest-description">{quest.description}</p>
      <p className="quest-info"><strong>Price:</strong> ${quest.price}</p>
      <p className="quest-info"><strong>Duration:</strong> {quest.duration} minutes</p>
      <p className="quest-info"><strong>Difficulty:</strong> {quest.difficulty}</p>
      {token ? (
        isPaid ? (
          <button className="start-button" onClick={handleStartQuest}>Start Quest</button>
        ) : (
          <button className="buy-button" onClick={handlePayment}>Pay Now</button>
        )
      ) : (
        <div className="auth-reminder">
          <p>You need to <a href="/login">login</a> or <a href="/register">register</a> to pay for this quest.</p>
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default QuestPage;
