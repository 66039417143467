import React from 'react';
import './RiddleStage.css';

const RiddleStage = ({ character, text, inputText, onInputChange, onSubmit, showHint, onHint, showGiveUp, onGiveUp, resultMessage }) => (
  <div className="riddle-stage-container">
    <h2>{character}</h2>
    <p>{text}</p>
    <form onSubmit={onSubmit}>
      <input type="text" value={inputText} onChange={onInputChange} />
      <button type="submit" className="styled-button">Submit</button>
    </form>
    {showHint && (
      <button className="styled-button" onClick={onHint}>Hint</button>
    )}
    {showGiveUp && (
      <button className="styled-button" onClick={onGiveUp}>Give up</button>
    )}
    <p>{resultMessage}</p>
  </div>
);

export default RiddleStage;

