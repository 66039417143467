import React, { useEffect, useState } from "react";
import "./App.css";
import StartScreen from "./components/StartScreen";
import FinishScreen from "./components/FinishScreen";
import Account from "./components/Account";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentCancel from "./components/PaymentCancel";
import QuestPage from "./components/QuestPage";
import SpeechStage from "./components/SpeechStage";
import RiddleStage from "./components/RiddleStage";
import ImageStage from "./components/ImageStage";
import Login from "./components/Login";
import Register from "./components/Register";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { io } from "socket.io-client";
import { Container } from '@mui/material';

const socket = io('wss://tnf.co.il/ws');

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [inputText, setInputText] = useState("");
  const [image, setImage] = useState(null);
  const [resultMessage, setResultMessage] = useState("");
  const [similarity, setSimilarity] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [quest, setQuest] = useState(null);
  const [questCompleted, setQuestCompleted] = useState(false);
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);
  const [showHint, setShowHint] = useState(false);
  const [showGiveUp, setShowGiveUp] = useState(false);
  const [role, setRole] = useState('');
  const [points, setPoints] = useState(100);
  const [extraTasksCounter, setExtraTasksCounter] = useState(0);
  const [timeLimit, setTimeLimit] = useState(null);
  const [extraTask, setExtraTask] = useState(null);
  const [deductedPoints, setDeductedPoints] = useState(null);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    socket.on('message', (data) => {
      console.log('Message from server:', data);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const saveToken = (userToken) => {
    localStorage.setItem("token", userToken);
    setToken(userToken);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  const ProtectedRoute = ({ children }) => {
    return token ? children : <Navigate to="/login" />;
  };

  const saveProgress = (questId, currentStep, currentSubStep) => {
    const progress = {
      questId,
      currentStep,
      currentSubStep,
      timestamp: Date.now()
    };
    localStorage.setItem('questProgress', JSON.stringify(progress));
  };

  const loadProgress = () => {
    const savedProgress = localStorage.getItem('questProgress');
    if (savedProgress) {
      const progress = JSON.parse(savedProgress);
      if (Date.now() - progress.timestamp < 3 * 60 * 60 * 1000) {
        return progress;
      } else {
        localStorage.removeItem('questProgress');
      }
    }
    return null;
  };

  const handleStart = async (questId, selectedRole) => {
    try {
      const response = await axios.get(`https://tnf.co.il/api/quests/${questId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setQuest(response.data);
      
      const savedProgress = loadProgress();
      if (savedProgress && savedProgress.questId === questId) {
        setCurrentStep(savedProgress.currentStep);
        setCurrentSubStep(savedProgress.currentSubStep);
      } else {
        setCurrentStep(1);
        setCurrentSubStep(0);
      }
      
      setQuestCompleted(false);
      resetState();
      setRole(selectedRole);
      setPoints(100);
    } catch (error) {
      console.error('Error fetching quest:', error);
    }
  };

  const resetState = () => {
    setInputText("");
    setResultMessage("");
    setSimilarity(null);
    setIncorrectAttempts(0);
    setShowHint(false);
    setShowGiveUp(false);
    setExtraTasksCounter(0);
    setExtraTask(null);
    setDeductedPoints(null);
  };

  const handleTextChange = (event) => {
    setInputText(event.target.value);
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleTextSubmit = (event) => {
    event.preventDefault();
    const currentStage = extraTask ? extraTask : quest.points[currentStep - 1].steps[currentSubStep];
    const correctAnswer = currentStage.correctAnswer.toLowerCase();

    if (inputText.toLowerCase() === correctAnswer) {
      resetState();
      setShowImageUpload(quest.points[currentStep - 1].steps[currentSubStep + 1]?.type === 'image');
      handleStepProgression();
    } else {
      handleIncorrectAttempt();
    }
  };

  const handleImageSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", image);
    formData.append("quest_id", quest._id);
    formData.append("point_index", currentStep - 1);
    formData.append("step_index", currentSubStep);

    if (extraTask) {
      formData.append("extra_task", true);
    }

    try {
      const response = await axios.post("https://tnf.co.il/api/upload-image/", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      });
      setResultMessage(response.data.message);
      setSimilarity(response.data.similarity);

      if (response.data.similarity >= 0.26) {
        resetState();
        if (extraTask) {
          setExtraTask(null);
          handleNextStep();
        } else {
          handleStepProgression();
        }
      } else {
        handleIncorrectAttempt(response.data.similarity);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setResultMessage("Error uploading image, try again.");
    }
  };

  const handleStepProgression = () => {
    if (isLastSubStep()) {
      if (isLastPoint()) {
        setQuestCompleted(true);
        localStorage.removeItem('questProgress');
      } else {
        handleNextStep();
      }
    } else {
      const newSubStep = currentSubStep + 1;
      setCurrentSubStep(newSubStep);
      saveProgress(quest._id, currentStep, newSubStep);
    }
  };

  const handleNextStep = () => {
    if (extraTasksCounter > 0 && extraTasksCounter % 4 === 0) {
      const extraTask = quest.extraTasks[Math.floor(Math.random() * quest.extraTasks.length)];
      setExtraTask(extraTask);
      if (extraTask.timeLimit) {
        setTimeLimit(extraTask.timeLimit);
      }
    } else {
      setExtraTask(null);
      if (currentSubStep + 1 >= quest.points[currentStep - 1].steps.length) {
        if (currentStep + 1 >= quest.points.length) {
          setQuestCompleted(true);
          localStorage.removeItem('questProgress');
        } else {
          const newStep = currentStep + 1;
          setCurrentStep(newStep);
          setCurrentSubStep(0);
          saveProgress(quest._id, newStep, 0);
        }
      } else {
        const newSubStep = currentSubStep + 1;
        setCurrentSubStep(newSubStep);
        saveProgress(quest._id, currentStep, newSubStep);
      }
    }
    setExtraTasksCounter(extraTasksCounter + 1);
  };

  const handleIncorrectAttempt = (similarity = null) => {
    setResultMessage(similarity !== null ? `Image does not match, try again. Similarity: ${similarity}` : "No! Try again.");
    const newIncorrectAttempts = incorrectAttempts + 1;
    setIncorrectAttempts(newIncorrectAttempts);
    deductPoints(5);

    if (newIncorrectAttempts >= 3) {
      setShowHint(true);
    }
    if (newIncorrectAttempts >= 7) {
      setShowGiveUp(true);
    }
  };

  const isLastSubStep = () => {
    const currentPoint = quest.points[currentStep - 1];
    return currentSubStep + 1 >= currentPoint.steps.length;
  };

  const isLastPoint = () => {
    return currentStep >= quest.points.length;
  };

  const deductPoints = (amount) => {
    setPoints(points - amount);
    setDeductedPoints(amount);
    setTimeout(() => setDeductedPoints(null), 1500);
  };

  const renderStepContent = () => {
    if (!quest) return null;

    const currentPoint = quest.points[currentStep - 1];
    const currentStage = extraTask ? extraTask : currentPoint.steps[currentSubStep];

    if (!currentStage) {
      console.error(`No stage found for currentStep: ${currentStep}, currentSubStep: ${currentSubStep}`);
      return <p>Error: No stage found for the current step and substep.</p>;
    }

    if (role === 'Listener' && currentStage.type !== 'speech') {
      handleNextStep();
      return null;
    }
    if (role === 'Gamer' && currentStage.type === 'speech') {
      handleNextStep();
      return null;
    }

    switch (currentStage.type) {
      case 'speech':
        return <SpeechStage character={currentPoint.character} text={currentStage.text} onNext={handleNextStep} />;
      case 'riddle':
        return <RiddleStage character={currentPoint.character} text={currentStage.text} inputText={inputText} onInputChange={handleTextChange} onSubmit={handleTextSubmit} showHint={showHint} onHint={() => { setResultMessage(`Hint: ${currentStage.hint}`); deductPoints(10); }} showGiveUp={showGiveUp} onGiveUp={() => { setResultMessage(`Give up! The correct answer is: ${currentStage.correctAnswer}`); deductPoints(20); }} resultMessage={resultMessage} />;
      case 'image':
        return <ImageStage character={currentPoint.character} text={currentStage.text} onFileChange={handleImageChange} onSubmit={handleImageSubmit} showHint={showHint} onHint={() => { setResultMessage(`Hint: ${currentStage.hint}`); deductPoints(10); }} showGiveUp={showGiveUp} onGiveUp={() => { setResultMessage(`Give up! The correct answer is: ${currentStage.correctAnswer}`); deductPoints(20); }} resultMessage={resultMessage} />;
      default:
        return null;
    }
  };

  return (
    <Router>
      <Container className="App">
        <Routes>
          <Route path="/" element={<StartScreen token={token} />} />
          <Route path="/account" element={
            <ProtectedRoute>
              <Account token={token} logout={logout} />
            </ProtectedRoute>
          } />
          <Route path="/login" element={<Login setToken={saveToken} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/quest/:id" element={<QuestPage token={token} onStart={handleStart} />} />
          <Route path="/execute" element={<PaymentSuccess />} />
          <Route path="/cancel" element={<PaymentCancel />} />
          <Route path="/quest" element={quest ? (
            questCompleted ? (
              <FinishScreen onRestart={() => setQuest(null)} points={points} />
            ) : (
              <div>
                <h1>{quest.name}</h1>
                {renderStepContent()}
              </div>
            )
          ) : (
            <StartScreen onSelectQuest={handleStart} />
          )} />
        </Routes>
        {deductedPoints !== null && (
          <div className="deducted-points-animation">
            -{deductedPoints}
          </div>
        )}
      </Container>
    </Router>
  );
}

export default App;
