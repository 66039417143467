import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';

const socket = io('https://tnf.co.il'); // Подключение к WebSocket серверу

const Login = ({ setToken }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    socket.on('message', (data) => {
      console.log('Message from server:', data);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Отправка данных для входа:', { email, password });
      const response = await axios.post('https://tnf.co.il/api/login', {
        email,
        password
      });
      console.log('Полный ответ сервера:', response);

      if (response.data && typeof response.data === 'object') {
        console.log('Содержимое response.data:', response.data);
        if (response.data.access_token && response.data.refresh_token) {
          console.log('Tokens received:', response.data);
          setToken(response.data.access_token);
          localStorage.setItem('refresh_token', response.data.refresh_token);
          navigate('/account');
        } else {
          console.error('Токены не найдены в ответе. Структура ответа:', response.data);
          throw new Error('Токены не найдены в ответе');
        }
      } else {
        console.error('Неожиданная структура ответа:', response);
        throw new Error('Неожиданная структура ответа');
      }
    } catch (error) {
      console.error('Ошибка при входе:', error.message);
      if (error.response) {
        if (error.response.status === 400) {
          setErrorMessage('Invalid email or password.');
        } else if (error.response.status === 401) {
          setErrorMessage('Unauthorized: Invalid or expired token.');
        } else if (error.response.status === 403) {
          setErrorMessage('Forbidden: Access denied.');
        } else {
          setErrorMessage(`Error: ${error.response.status} - ${error.response.data.detail}`);
        }
      } else {
        setErrorMessage('Error: Unable to connect to the server');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Login</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <button type="submit">Login</button>
    </form>
  );
};

export default Login;

