import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Account.css';

const Account = ({ token, logout }) => {
  const [userData, setUserData] = useState({});
  const [questHistory, setQuestHistory] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [purchasedQuests, setPurchasedQuests] = useState([]);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    const storedQuestHistory = localStorage.getItem('questHistory');
    const storedTransactionHistory = localStorage.getItem('transactionHistory');
    const storedPurchasedQuests = localStorage.getItem('purchasedQuests');

    if (storedUserData) setUserData(JSON.parse(storedUserData));
    if (storedQuestHistory) setQuestHistory(JSON.parse(storedQuestHistory));
    if (storedTransactionHistory) setTransactionHistory(JSON.parse(storedTransactionHistory));
    if (storedPurchasedQuests) setPurchasedQuests(JSON.parse(storedPurchasedQuests));

    if (!storedUserData || !storedQuestHistory || !storedTransactionHistory || !storedPurchasedQuests) {
      // Fetch user profile data
      axios.get('https://tnf.co.il/api/account/profile', {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(response => {
          setUserData(response.data);
          localStorage.setItem('userData', JSON.stringify(response.data));
        })
        .catch(error => console.error('Error fetching user profile:', error));

      // Fetch quest history
      axios.get('https://tnf.co.il/api/account/quest-history', {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(response => {
          setQuestHistory(response.data);
          localStorage.setItem('questHistory', JSON.stringify(response.data));
        })
        .catch(error => console.error('Error fetching quest history:', error));

      // Fetch transaction history
      axios.get('https://tnf.co.il/api/account/transaction-history', {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(response => {
          setTransactionHistory(response.data);
          localStorage.setItem('transactionHistory', JSON.stringify(response.data));
        })
        .catch(error => console.error('Error fetching transaction history:', error));

      // Fetch purchased quests
      axios.get('https://tnf.co.il/api/account/purchased-quests', {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(response => {
          setPurchasedQuests(response.data);
          localStorage.setItem('purchasedQuests', JSON.stringify(response.data));
        })
        .catch(error => console.error('Error fetching purchased quests:', error));
    }
  }, [token]);

  return (
    <div className="account-container">
      <h1>Account</h1>
      <button className="logout-button" onClick={logout}>Logout</button>
      <div className="profile-info">
        <h2>Profile</h2>
        <p>Name: {userData.name}</p>
        <p>Email: {userData.email}</p>
        <img src={userData.avatar} alt="User Avatar" className="avatar" />
      </div>
      <div className="history-section">
        <h2>Quest History</h2>
        <ul>
          {questHistory.map((quest, index) => (
            <li key={index}>
              {quest.quest_name} - {quest.points} points - {quest.date}
            </li>
          ))}
        </ul>
      </div>
      <div className="history-section">
        <h2>Transaction History</h2>
        <ul>
          {transactionHistory.map((transaction, index) => (
            <li key={index}>
              {transaction.transaction_id} - ${transaction.amount} - {transaction.status} - {transaction.date}
            </li>
          ))}
        </ul>
      </div>
      <div className="history-section">
        <h2>Purchased Quests</h2>
        <ul>
          {purchasedQuests.map((quest, index) => (
            <li key={index}>{quest.quest_id}</li>
          ))}
        </ul>
      </div>
      <Link to="/" className="back-link">Back to Home</Link>
    </div>
  );
};

export default Account;

